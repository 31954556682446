import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import isMobile from 'is-mobile'
import PasskeyIcon from '@ubnt/icons/PasskeyIcon'
import AuthIcon from '@ubnt/icons/AuthIcon'
import MailsCloseIcon from '@ubnt/icons/MailsCloseIcon'
import { MFAOption } from 'pages/security/components/SetupMFAModal/MFAOption'
import UIVerify from 'assets/svgs/uiVerify.svg'
import { initiateMFAPush } from 'pages/security/components/Push/modules/initiatePush'
import { initiateMFATOTP } from 'pages/security/components/TOTP/modules/initiateTOTP'
import { setVisibleModal } from 'modules/modals'
import { MFAType } from 'modules/mfa'
import { resetInitiateEmailData } from 'pages/security/components/Email/modules/initiateEmail'

import { useVerifyCountryLimitation } from './useVerifyCountryLimitation'
import { getNextModalId } from './utils'

const { featureFlags } = __CONFIG__

export const useGetMFAOptions = (): {
  featured: ReactElement | null
  other: (ReactElement | null)[]
} => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isMobileDevice = isMobile()
  const { hasVerifyCountryLimitation } = useVerifyCountryLimitation()

  const uiVerify = (
    <MFAOption
      iconLink={UIVerify}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_METHOD_PUSH' })}
      description={intl.formatMessage({
        id: 'SETTINGS_MFA_METHOD_PUSH_DESCRIPTION',
      })}
      onClick={() => dispatch(initiateMFAPush())}
      focusOnLoad
      isRecommended={!hasVerifyCountryLimitation}
      largerIcon
    />
  )

  const totp = (
    <MFAOption
      icon={<AuthIcon variant="twoTone" isActive size={32} />}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_METHOD_TOTP' })}
      description={intl.formatMessage({
        id: 'SETTINGS_MFA_METHOD_TOTP_DESCRIPTION',
      })}
      onClick={() => {
        dispatch(initiateMFATOTP())
        dispatch(setVisibleModal(getNextModalId(MFAType.totp, isMobileDevice)))
      }}
    />
  )

  const email: ReactElement = (
    <MFAOption
      icon={<MailsCloseIcon variant="twoTone" isActive size={32} />}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_METHOD_EMAIL' })}
      description={intl.formatMessage({
        id: 'SETTINGS_MFA_METHOD_EMAIL_DESCRIPTION',
      })}
      onClick={() => {
        dispatch(setVisibleModal(getNextModalId(MFAType.email)))
        dispatch(resetInitiateEmailData())
      }}
    />
  )

  const webAuthn: ReactElement | null =
    window.PublicKeyCredential && featureFlags.webAuthn ? (
      <MFAOption
        icon={<PasskeyIcon variant="twoTone" isActive size={32} />}
        title={intl.formatMessage({
          id: 'SETTINGS_MFA_METHOD_PASSKEY',
        })}
        description={intl.formatMessage({
          id: 'SETTINGS_MFA_METHOD_PASSKEY_DESCRIPTION',
        })}
        onClick={() =>
          dispatch(setVisibleModal(getNextModalId(MFAType.webAuthn)))
        }
        tabIndex={4}
      />
    ) : null

  if (hasVerifyCountryLimitation) {
    return {
      featured: email,
      other: [uiVerify, totp, webAuthn],
    }
  }

  return {
    featured: uiVerify,
    other: [totp, webAuthn, email],
  }
}
