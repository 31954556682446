export enum ProductName {
  MOBILITY = 'UniFi Mobile Routing',
  HOSTING = 'Official UniFi Hosting',
  UNIFI_HOSTING = 'unifi-hosting',
  ULTE = 'UniFi LTE',
  UNIFI_TALK = 'UniFi Talk',
  TALK = 'talk',
  SITE_SUPPORT = 'Site Support',
  UISP_HOSTING = 'Official UISP Hosting',
  ENHANCED_TREAT_UPDATES = 'Enhanced Threat Updates',
  UNKNOWN = 'Unknown',
}

export enum DomException {
  INVALID_STATE_ERROR = 'InvalidStateError',
  NOT_ALLOWED_ERROR = 'NotAllowedError',
  ABORT_ERROR = 'AbortError',
}
