import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'
import createDataModule from 'utils/moduleCreator'
import { fetchTrustedDevices, TrustedDevice } from './trustedDevices'

const {
  api: { sso },
} = __CONFIG__

export const trustDeviceDataKey = 'trustDevice'

const dataModule = createDataModule<TrustedDevice>(
  trustDeviceDataKey,
  sso.paths.device,
  api.ssoBase
)

export const {
  fetch: fetchDeviceInfo,
  selectIsFetchLoading: selectDeviceInfoLoading,
  selectData: selectDeviceInfo,
  selectErrors,
  selectHasFetchedOnce,
  reducer: trustDeviceReducer,
  resetErrors,
  CREATE_DONE,
} = dataModule

export const addTrustedDevice = (device_id: string) => {
  return {
    type: `${trustDeviceDataKey}/CREATE`,
    payload: { device_id },
  }
}

function* addTrustedDeviceDoneSaga() {
  yield put(fetchTrustedDevices())
}

function* subscribeToCreateDoneSaga() {
  yield takeEvery(CREATE_DONE, addTrustedDeviceDoneSaga)
}

export function* trustDeviceRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToCreateDoneSaga)])
}
