import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import '@ubnt/ui-components/styles/normalize.css'
import '@ubnt/ui-components/styles/reset.css'
import GlobalStyles from 'theme/GlobalStyles'
import { twoFaRootSaga } from 'modules/twoFa'
import { authRootSaga } from 'features/auth/modules/auth'
import { assuranceLevelRootSaga } from 'features/auth/modules/assuranceLevel'
import { createAccountRootSaga } from 'features/auth/modules/createAccount'
import { createCardRootSaga } from 'features/payment-methods/modules/addCard'
import { removeCardRootSaga } from 'features/payment-methods/modules/removeCard'
import { subscriptionsRootSaga } from 'features/subscriptions/module/subscriptions'
import { ncaRootSaga } from 'features/backups/module/nca'
import { subscriptionsUpdateRootSaga } from 'features/subscriptions/module/subscriptionsUpdate'
import { invoicesUpdateRootSaga } from 'features/subscriptions/module/invoicesUpdate'
import { productsRootSaga } from 'features/subscriptions/module/products'
import { stripeCustomerRootSaga } from 'features/stripe/modules/stripeCustomer'
import { profilePictureRootSaga } from 'features/profile-picture/module/profilePicture'
import { generateBackupRootSaga } from 'pages/security/components/BackupCodes/modules'
import { twoFaResetRootSaga } from 'features/auth/modules/twoFaReset'
import { rolesRootSaga } from 'features/early-access/module/roles'
import { invoicesRootSaga } from 'features/subscriptions/module/invoices'
import { invoicesUpcomingRootSaga } from 'features/subscriptions/module/invoicesUpcoming'
import { deviceLteRootSaga } from 'features/subscriptions/module/deviceLte'
import { deleteAccountRootSaga } from 'features/delete-account/module/deleteAccount'
import GlobalErrorBoundry from 'core/error-handling/GlobalErrorBoundry'
import { ReCaptchaProviderConnected } from 'components/ReCaptcha'
import { deviceTalkCancelRootSaga } from 'features/subscriptions/module/deviceTalkCancel'
import { reauthMFARootSaga } from 'features/auth/modules/reauth'
import { loginPasskeyRootSaga } from 'pages/security/components/Passkey/modules/loginPasskey'
import { devicesRootSaga } from 'features/devices/devices'
import { addressesRootSaga } from 'features/addresses/module/addresses'
import { activityRootSaga } from 'pages/activity/module/activity'
import { sessionRootSaga } from 'modules/session'
import { deviceBackupsRootSaga } from 'features/backups/module/backups'
import { changeEmailRootSaga } from 'pages/security/changeEmail'
import { updateCardRootSaga } from 'features/payment-methods/modules/updateCard'
import { invoiceLinesRootSaga } from 'features/subscriptions/module/invoiceLines'
import { BackupsProvider } from 'features/backups/BackupsContext'
import { MotifProvider } from 'motif/MotifProvider'
import { updateStripeCustomerRootSaga } from 'features/stripe/modules/updateStripeCustomer'
import { createStripeCustomerRootSaga } from 'features/stripe/modules/createStripeCustomer'
import { deviceUmrInfoRootSaga } from 'features/subscriptions/module/deviceUmr'
import { createTaxIdRootSaga } from 'features/payment-methods/modules/taxId/addTaxId'
import { fetchTaxIdRootSaga } from 'features/payment-methods/modules/taxId/getTaxId'
import { updateTaxIdRootSaga } from 'features/payment-methods/modules/taxId/updateTaxId'
import { removeTaxIdRootSaga } from 'features/payment-methods/modules/taxId/removeTaxId'
import { openTicketsRootSaga } from 'modules/zendesk/openUserTickets'
import { deviceSiteSupportCancelRootSaga } from 'features/subscriptions/module/deviceSiteSupportCancel'
import { statusApiRootSaga } from 'features/status/modules/statusApi'
import { initiateEmailRootSaga } from 'pages/security/components/Email/modules/initiateEmail'
import { removeEmailRootSaga } from 'pages/security/components/Email/modules/removeEmail'
import { removeTempEmailRootSaga } from 'pages/security/components/Email/modules/removeTempEmail'
import { sendEmailRootSaga } from 'pages/security/components/Email/modules/sendEmail'
import { verifyEmailRootSaga } from 'pages/security/components/Email/modules/verifyEmail'
import { initiatePushRootSaga } from 'pages/security/components/Push/modules/initiatePush'
import { loginPushRootSaga } from 'pages/security/components/Push/modules/loginPush'
import { removePendingPushRootSaga } from 'pages/security/components/Push/modules/removePendingPush'
import { removePushRootSaga } from 'pages/security/components/Push/modules/removePush'
import { verifyPushRootSaga } from 'pages/security/components/Push/modules/verifyPush'
import { initiateMFASMSRootSaga } from 'pages/security/components/SMS/modules/initiateMFASMS'
import { removeMFASMSRootSaga } from 'pages/security/components/SMS/modules/removeMFASMS'
import { sendMFASMSRootSaga } from 'pages/security/components/SMS/modules/sendMFASMS'
import { verifyMFASMSRootSaga } from 'pages/security/components/SMS/modules/verifyMFASMS'
import { removePasskeyRootSaga } from 'pages/security/components/Passkey/modules/removePasskey'
import { setupPasskeyRootSaga } from 'pages/security/components/Passkey/modules/setupPasskey'
import { initiateTOTPRootSaga } from 'pages/security/components/TOTP/modules/initiateTOTP'
import { removeTOTPRootSaga } from 'pages/security/components/TOTP/modules/removeTOTP'
import { updateTOTPRootSaga } from 'pages/security/components/TOTP/modules/updateTOTP'
import { verifyTOTPRootSaga } from 'pages/security/components/TOTP/modules/verifyTOTP'
import { umrDevicesRootSaga } from 'features/devices/umrDevices'
import { announcementsRootSaga } from 'features/announcements/module/announcements'
import { editAnnouncementsRootSaga } from 'features/announcements/module/editAnnouncements'
import { closedTicketsRootSaga } from 'modules/zendesk/closedUserTickets'
import { ccdTicketsRootSaga } from 'modules/zendesk/ccdUserTickets'
import { MediaSizingProvider } from 'components/mediaSizing/MediaSizingContext'
import { trustedDevicesRootSaga } from 'modules/trustedDevices'
import { uiTaxIdsRootSaga } from 'features/subscriptions/module/uiTaxIds'
import { BusinessProfileRootSaga } from 'features/subscriptions/module/businessProfile'
import packageJSON from '../package.json'
import storeConfigs from './store'
import IntlProvider from './pages/IntlProvider'
import App from './pages/App'
import { legalRootSaga } from './modules/legal'
import {
  subscribeProfileUpdateDoneSaga,
  subscribeToSetProfileSaga,
} from './modules/profile'
import { mfaRootSaga } from './modules/mfa'
import { trustDeviceRootSaga } from 'modules/trustDevice'
import { loginIssueFeedbackRootSaga } from 'features/auth/modules/loginIssueFeedback'
import { fetchPaymentMethodsRootSaga } from 'features/payment-methods/modules/fetchPaymentMethods'

const { store, sagaMiddleware } = storeConfigs

sagaMiddleware.run(statusApiRootSaga)
sagaMiddleware.run(authRootSaga)
sagaMiddleware.run(initiateTOTPRootSaga)
sagaMiddleware.run(subscribeToSetProfileSaga)
sagaMiddleware.run(legalRootSaga)
sagaMiddleware.run(createAccountRootSaga)
sagaMiddleware.run(createCardRootSaga)
sagaMiddleware.run(removeCardRootSaga)
sagaMiddleware.run(createTaxIdRootSaga)
sagaMiddleware.run(fetchTaxIdRootSaga)
sagaMiddleware.run(removeTaxIdRootSaga)
sagaMiddleware.run(subscribeProfileUpdateDoneSaga)
sagaMiddleware.run(stripeCustomerRootSaga)
sagaMiddleware.run(updateStripeCustomerRootSaga)
sagaMiddleware.run(createStripeCustomerRootSaga)
sagaMiddleware.run(twoFaRootSaga)
sagaMiddleware.run(mfaRootSaga)
sagaMiddleware.run(reauthMFARootSaga)
sagaMiddleware.run(removeTOTPRootSaga)
sagaMiddleware.run(verifyTOTPRootSaga)
sagaMiddleware.run(updateTOTPRootSaga)
sagaMiddleware.run(subscriptionsRootSaga)
sagaMiddleware.run(subscriptionsUpdateRootSaga)
sagaMiddleware.run(productsRootSaga)
sagaMiddleware.run(profilePictureRootSaga)
sagaMiddleware.run(generateBackupRootSaga)
sagaMiddleware.run(deleteAccountRootSaga)
sagaMiddleware.run(twoFaResetRootSaga)
sagaMiddleware.run(rolesRootSaga)
sagaMiddleware.run(invoicesRootSaga)
sagaMiddleware.run(invoiceLinesRootSaga)
sagaMiddleware.run(uiTaxIdsRootSaga)
sagaMiddleware.run(BusinessProfileRootSaga)
sagaMiddleware.run(invoicesUpcomingRootSaga)
sagaMiddleware.run(deviceLteRootSaga)
sagaMiddleware.run(invoicesUpdateRootSaga)
sagaMiddleware.run(deviceTalkCancelRootSaga)
sagaMiddleware.run(setupPasskeyRootSaga)
sagaMiddleware.run(removePasskeyRootSaga)
sagaMiddleware.run(loginPasskeyRootSaga)
sagaMiddleware.run(initiateMFASMSRootSaga)
sagaMiddleware.run(verifyMFASMSRootSaga)
sagaMiddleware.run(removeMFASMSRootSaga)
sagaMiddleware.run(sendMFASMSRootSaga)
sagaMiddleware.run(initiateEmailRootSaga)
sagaMiddleware.run(verifyEmailRootSaga)
sagaMiddleware.run(sendEmailRootSaga)
sagaMiddleware.run(removeEmailRootSaga)
sagaMiddleware.run(removeTempEmailRootSaga)
sagaMiddleware.run(initiatePushRootSaga)
sagaMiddleware.run(verifyPushRootSaga)
sagaMiddleware.run(loginPushRootSaga)
sagaMiddleware.run(removePushRootSaga)
sagaMiddleware.run(removePendingPushRootSaga)
sagaMiddleware.run(deviceBackupsRootSaga)
sagaMiddleware.run(ncaRootSaga)
sagaMiddleware.run(assuranceLevelRootSaga)
sagaMiddleware.run(devicesRootSaga)
sagaMiddleware.run(addressesRootSaga)
sagaMiddleware.run(activityRootSaga)
sagaMiddleware.run(sessionRootSaga)
sagaMiddleware.run(openTicketsRootSaga)
sagaMiddleware.run(closedTicketsRootSaga)
sagaMiddleware.run(ccdTicketsRootSaga)
sagaMiddleware.run(changeEmailRootSaga)
sagaMiddleware.run(updateCardRootSaga)
sagaMiddleware.run(updateTaxIdRootSaga)
sagaMiddleware.run(deviceUmrInfoRootSaga)
sagaMiddleware.run(umrDevicesRootSaga)
sagaMiddleware.run(deviceSiteSupportCancelRootSaga)
sagaMiddleware.run(announcementsRootSaga)
sagaMiddleware.run(editAnnouncementsRootSaga)
sagaMiddleware.run(trustedDevicesRootSaga)
sagaMiddleware.run(trustDeviceRootSaga)
sagaMiddleware.run(loginIssueFeedbackRootSaga)
sagaMiddleware.run(fetchPaymentMethodsRootSaga)

window.__RELEASE__ = __RELEASE__

if (!__DEV__) {
  const tagManagerArgs = {
    gtmId: 'GTM-P5VBRWW',
  }

  TagManager.initialize(tagManagerArgs)

  Sentry.init({
    dsn: 'https://b185b3ad1240a5ed1a7199d1e49e105a@o21767.ingest.sentry.io/4506058886676480',
    tracesSampleRate: 0,
    release: `account@${packageJSON.version}`,
    // TODO: Improve. Now setting on hour, not minutes, seconds or milliseconds so within hour they will interfere
    dist: new Date().setMinutes(0, 0, 0).toString(),
  })
}

const load = () => (
  <Provider store={store}>
    <MotifProvider>
      <GlobalStyles />
      <MediaSizingProvider>
        <IntlProvider>
          <GlobalErrorBoundry>
            <ReCaptchaProviderConnected>
              <BackupsProvider>
                <App />
              </BackupsProvider>
            </ReCaptchaProviderConnected>
          </GlobalErrorBoundry>
        </IntlProvider>
      </MediaSizingProvider>
    </MotifProvider>
  </Provider>
)

ReactDOM.render(load(), document.getElementById('root'))
