import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, ButtonLink } from '@ubnt/ui-components/Button'
import { History } from 'history'
import { DateTime } from 'luxon'
import { Tooltip } from '@ubnt/ui-components/Tooltip'

import {
  AuthError,
  resetAuthErrors,
  selectAuthenticators,
  selectCanChangeEmail,
  selectCanSubmitFeedback,
  selectDefaultMFA,
  selectErrors,
  selectIsLoading,
} from 'features/auth/modules/auth'
import authorizedRedirect from 'components/authorizedRedirect'
import PublicPage from 'pages/PublicPage'
import { RootState } from 'types/types'
import { DefaultReauthForm } from 'pages/security/components/ReauthModal/DefaultForm'
import { MFAAuthenticator, MFAType } from 'modules/mfa'
import {
  loginPasskey,
  resetLoginRequestPasskeyData,
  resetLoginVerifyPasskeyData,
  selectLoginRequestPasskeyErrors,
  selectLoginVerifyPasskeyErrors,
} from 'pages/security/components/Passkey/modules/loginPasskey'
import {
  initiateLoginMFAPush,
  loginMFAPush,
} from 'pages/security/components/Push/modules/loginPush'
import { setVisibleModal } from 'modules/modals'
import styled from 'theme/styled'
import { space, typography } from 'theme'
import {
  MFAOptionsModal,
  MFA_OPTIONS_MODAL_ID,
} from 'components/mfa-options-modal/MFAOptionsModal'
import { LostAccessModal } from 'components/mfa-options-modal/LostAccessModal'
import {
  resetSendMFASMSData,
  selectSendMFASMSErrors,
  selectSendMFASMSIsLoading,
  sendMFASMS,
} from 'pages/security/components/SMS/modules/sendMFASMS'
import {
  resetSendEmailData,
  selectSendEmailErrors,
  selectSendEmailIsLoading,
  sendMFAEmail,
} from 'pages/security/components/Email/modules/sendEmail'

import LoginMFAPush from './LoginMFAForm/LoginMFAPush'
import LoginMFAPasskey from './LoginMFAForm/LoginMFAPasskey'
import LoginMFABackupCodes from './LoginMFAForm/LoginMFABackupCodes'
import LoginMFAEmail from './LoginMFAForm/LoginMFAEmail'
import LoginMFASMS from './LoginMFAForm/LoginMFASMS'
import LoginMFATOTP from './LoginMFAForm/LoginMFATOTP'
import { storedTrustDeviceRedirectKey } from 'features/redirect-after-register'

import { selectHasSubmittedMfaFeedbackRecently } from '../modules/loginIssueFeedback'
interface Props {
  isLoading: boolean
  isAuthenticated: boolean
  apiErrors: AuthError
  history: History
  sendSMS: typeof sendMFASMS
  sendEmail: typeof sendMFAEmail
  passkeyLogin: typeof loginPasskey
  loginPush: typeof initiateLoginMFAPush
  authenticators?: MFAAuthenticator[] | null
  defaultMFA?: string | null
}

const {
  featureFlags: { isOneTimeEmailChangeEnabled },
} = __CONFIG__

export const LoginMFA: React.FC<Props> = (props) => {
  const {
    sendEmail,
    sendSMS,
    passkeyLogin,
    loginPush,
    authenticators,
    defaultMFA,
    isLoading,
    apiErrors,
  } = props

  const intl = useIntl()
  const dispatch = useDispatch()

  const smsError = useSelector(selectSendMFASMSErrors)
  const smsReset = useSelector(resetSendMFASMSData)
  const resendSMSIsLoading = useSelector(selectSendMFASMSIsLoading)
  const emailError = useSelector(selectSendEmailErrors)
  const emailReset = useSelector(resetSendEmailData)
  const resendEmailIsLoading = useSelector(selectSendEmailIsLoading)
  const passkeyVerifyError = useSelector(selectLoginVerifyPasskeyErrors)
  const passkeyVerifyReset = useSelector(resetLoginVerifyPasskeyData)
  const passkeyRequestReset = useSelector(resetLoginRequestPasskeyData)
  const passkeyRequestError = useSelector(selectLoginRequestPasskeyErrors)
  const canChangeEmail = useSelector(selectCanChangeEmail)

  const canSubmitMfaFeedback = useSelector(selectCanSubmitFeedback)
  const hasSubmittedMfaFeedbackRecently = useSelector(
    selectHasSubmittedMfaFeedbackRecently
  )

  const [currentAuthenticator, setCurrentAuthenticator] = useState<
    MFAAuthenticator | undefined
  >()
  const [prevAuthenticator, setPrevAuthenticator] = useState<
    MFAAuthenticator | undefined
  >()

  const redirectToLoginOnSessionExpired = useCallback(() => {
    props.history.push({
      pathname: `/login/`,
      state: { isSessionExpired: true },
    })
  }, [props.history])

  useEffect(() => {
    const storedRedirect = localStorage.getItem(storedTrustDeviceRedirectKey)

    if (!storedRedirect) {
      localStorage.setItem(
        storedTrustDeviceRedirectKey,
        JSON.stringify({
          url: '/login/trust-device',
          createdAt: DateTime.utc().toISO(),
        })
      )
    }
  }, [])

  useEffect(() => {
    const sessionTimeout = history.state?.state?.sessionTimeout
    const timer = setInterval(() => {
      if (sessionTimeout && sessionTimeout - Date.now() <= 0) {
        redirectToLoginOnSessionExpired()
      }
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [redirectToLoginOnSessionExpired])

  useEffect(() => {
    return () => {
      dispatch(resetAuthErrors())
    }
  }, [dispatch])

  useEffect(() => {
    if (!isLoading && !authenticators) {
      redirectToLoginOnSessionExpired()
    }
  }, [
    authenticators,
    isLoading,
    props.history,
    redirectToLoginOnSessionExpired,
  ])

  useEffect(() => {
    if (
      (typeof smsError === 'string' && smsError === 'Session expired') ||
      (apiErrors?.detail && apiErrors?.detail === 'unauthorized')
    ) {
      dispatch(smsReset)
      redirectToLoginOnSessionExpired()
    }
    if (
      (typeof emailError === 'string' && emailError === 'Session expired') ||
      (apiErrors?.detail && apiErrors?.detail === 'unauthorized')
    ) {
      dispatch(emailReset)
      redirectToLoginOnSessionExpired()
    }
    if (
      (typeof passkeyVerifyError === 'string' &&
        passkeyVerifyError === 'Session expired') ||
      (typeof passkeyRequestError === 'string' &&
        passkeyRequestError === 'Session expired')
    ) {
      dispatch(passkeyVerifyReset)
      dispatch(passkeyRequestReset)
      redirectToLoginOnSessionExpired()
    }
  }, [
    smsError,
    emailError,
    passkeyRequestError,
    passkeyVerifyError,
    props.history,
    dispatch,
    smsReset,
    emailReset,
    passkeyVerifyReset,
    passkeyRequestReset,
    redirectToLoginOnSessionExpired,
    apiErrors,
  ])

  const defaultAuthenticator = useMemo(() => {
    if (isLoading) {
      return null
    }

    return authenticators?.find(
      (authenticator: MFAAuthenticator) => authenticator.id === defaultMFA
    )
  }, [authenticators, defaultMFA, isLoading])

  // Set current method to default method on mount
  useEffect(() => {
    dispatch(resetAuthErrors())
    if (defaultAuthenticator && !prevAuthenticator) {
      setCurrentAuthenticator(defaultAuthenticator)
      setPrevAuthenticator(defaultAuthenticator)
      if (defaultAuthenticator.type === MFAType.push) dispatch(loginMFAPush())
      if (defaultAuthenticator.type === MFAType.webAuthn) passkeyLogin()
    }
  }, [
    currentAuthenticator,
    prevAuthenticator,
    defaultAuthenticator,
    dispatch,
    passkeyLogin,
  ])

  // Send tokens or trigger webauthn/push when changing to respective authenticators
  useEffect(() => {
    if (currentAuthenticator !== prevAuthenticator) {
      if (currentAuthenticator?.type === MFAType.webAuthn) {
        passkeyLogin()
        setPrevAuthenticator(currentAuthenticator)
      }
      if (currentAuthenticator?.type === MFAType.push) {
        loginPush()
        setPrevAuthenticator(currentAuthenticator)
      }
      if (currentAuthenticator?.type === MFAType.sms) {
        sendSMS?.(currentAuthenticator.id)
        setPrevAuthenticator(currentAuthenticator)
      }
      if (currentAuthenticator?.type === MFAType.email) {
        sendEmail?.(currentAuthenticator?.id)
        setPrevAuthenticator(currentAuthenticator)
      }
    }
  }, [
    currentAuthenticator,
    loginPush,
    passkeyLogin,
    sendEmail,
    sendSMS,
    prevAuthenticator,
    dispatch,
  ])

  const handleResend = () => {
    if (currentAuthenticator?.type === MFAType.webAuthn) {
      passkeyLogin()
    } else if (currentAuthenticator?.type === MFAType.push) {
      loginPush()
    } else if (currentAuthenticator?.type === MFAType.email) {
      sendEmail?.(currentAuthenticator?.id)
    } else if (currentAuthenticator?.type === MFAType.sms) {
      sendSMS?.(currentAuthenticator?.id)
    }
  }

  const emailToShow =
    currentAuthenticator?.type === MFAType.email
      ? currentAuthenticator.email
      : ''
  const phoneNumberToShow =
    currentAuthenticator?.type === MFAType.sms
      ? currentAuthenticator.phone_number
      : ''

  const selectedForm = () => {
    switch (currentAuthenticator?.type) {
      case MFAType.push:
        return (
          <LoginMFAPush
            {...props}
            handleResend={handleResend}
            currentAuthenticator={currentAuthenticator}
          />
        )
      case MFAType.sms:
        return (
          <LoginMFASMS
            {...props}
            handleResend={handleResend}
            resendIsLoading={resendSMSIsLoading}
            phoneNumber={phoneNumberToShow}
          />
        )
      case MFAType.email:
        return (
          <LoginMFAEmail
            {...props}
            handleResend={handleResend}
            resendIsLoading={resendEmailIsLoading}
            email={emailToShow}
          />
        )
      case MFAType.backupCodes:
        return <LoginMFABackupCodes {...props} />
      case MFAType.webAuthn:
        return <LoginMFAPasskey handleResend={handleResend} {...props} />
      case MFAType.totp:
        return <LoginMFATOTP {...props} />
      case undefined:
        return null
      default:
        return <DefaultReauthForm isLoading={isLoading} />
    }
  }

  const showNoDefaultMethodScreen = useMemo(
    () => defaultMFA === null && !isLoading && !currentAuthenticator,
    [currentAuthenticator, defaultMFA, isLoading]
  )

  const isEmailOrTOTP =
    currentAuthenticator?.type === 'email' ||
    currentAuthenticator?.type === 'totp'

  const isPasskey = currentAuthenticator?.type === MFAType.webAuthn

  return (
    <>
      <PublicPage
        showLogo={isEmailOrTOTP}
        formHeight={isPasskey ? 420 : undefined}
      >
        <Container>
          <InnerContainer>
            {showNoDefaultMethodScreen ? null : selectedForm()}
            {showNoDefaultMethodScreen ? (
              <ButtonWrapper>
                <StyledButton
                  variant="primary"
                  onClick={() =>
                    dispatch(setVisibleModal(MFA_OPTIONS_MODAL_ID))
                  }
                >
                  <FormattedMessage id="SETTINGS_MFA_OPTIONS_OTHER_METHODS" />
                </StyledButton>
              </ButtonWrapper>
            ) : (
              <>
                <StyledButtonLink
                  onClick={() =>
                    dispatch(setVisibleModal(MFA_OPTIONS_MODAL_ID))
                  }
                >
                  <FormattedMessage id="SETTINGS_MFA_OPTIONS_OTHER_METHODS" />
                </StyledButtonLink>
                {canChangeEmail && isOneTimeEmailChangeEnabled && (
                  <StyledButtonLink
                    onClick={() =>
                      props.history.push({
                        pathname: `/login/change-email`,
                        state: { email: emailToShow },
                      })
                    }
                  >
                    <FormattedMessage id="LOGIN_CHANGE_EMAIL_PROMPT" />
                  </StyledButtonLink>
                )}
                {canSubmitMfaFeedback && (
                  <Tooltip
                    description={intl.formatMessage({
                      id: 'LOGIN_MFA_FEEDBACK_TOOLTIP',
                    })}
                    isOpen={hasSubmittedMfaFeedbackRecently ? undefined : false}
                    portal
                  >
                    <StyledButtonLink
                      onClick={() =>
                        props.history.push({
                          pathname: `/login/mfa-feedback`,
                        })
                      }
                      disabled={hasSubmittedMfaFeedbackRecently}
                    >
                      <FormattedMessage id="LOGIN_MFA_FEEDBACK_LINK" />
                    </StyledButtonLink>
                  </Tooltip>
                )}
              </>
            )}
          </InnerContainer>
          <Link to="/login">
            <StyledButtonLinkBack>
              {intl.formatMessage({ id: 'COMMON_ACTION_BACK' })}
            </StyledButtonLinkBack>
          </Link>
        </Container>
      </PublicPage>
      <MFAOptionsModal
        authenticators={authenticators}
        setCurrentAuthenticator={setCurrentAuthenticator}
        currentAuthenticator={currentAuthenticator}
      />
      <LostAccessModal history={props.history} />
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  defaultMFA: selectDefaultMFA(state),
  authenticators: selectAuthenticators(state),
  isLoading: selectIsLoading(state),
  apiErrors: selectErrors(state),
})

const mapDispatchToProps = {
  sendEmail: sendMFAEmail,
  sendSMS: sendMFASMS,
  passkeyLogin: loginPasskey,
  loginPush: initiateLoginMFAPush,
}

export default authorizedRedirect(
  connect(mapStateToProps, mapDispatchToProps)(LoginMFA)
)

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: ${space['desktop-spacing-base-12']};
  font-weight: bold;
`

const StyledButtonLink = styled(ButtonLink)`
  font: ${typography['desktop-body']} !important;
  margin-bottom: ${space['desktop-spacing-base-02']};
`

const StyledButtonLinkBack = styled(ButtonLink)`
  font: ${typography['desktop-body']} !important;
`

const StyledButton = styled(Button)`
  margin-bottom: ${space['desktop-spacing-base-05']};
`

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
