import React from 'react'
import { useSelector } from 'react-redux'

import { selectIsTwoFactorAuthEnabled } from 'modules/profile'
import { Section } from 'components/Section.styles'

import {
  MultiFactorContent,
  MultiFactorHeader,
  RecoveryCodes,
} from './components/MultiFactor'
import { SetupMFAModal } from './components/SetupMFAModal'
import {
  TOTPCompletedModal,
  TOTPQRCodeModal,
  TOTPSecretCodeModal,
  TOTPVerifyCodeModal,
  WrappedTOTPRemoveModal,
} from './components/TOTP'
import {
  EmailCompletedModal,
  EmailInputEmailModal,
  EmailVerifyCodeModal,
  WrappedEmailRemoveModal,
} from './components/Email'
import {
  PushCompletedModal,
  PushFailedModal,
  PushQRCodeModal,
  WrappedPushRemoveModal,
} from './components/Push'
import { SetupPrimaryMethodModal } from './components/PrimaryMethodModal'
import { WrappedSubmitPasswordModal } from './SubmitPasswordModal'
import { BackupCodesFailedModal } from './components/BackupCodes/BackupCodesFailedModal'
import { BackupCodesModal } from './components/BackupCodes/BackupCodesModal'
import { BackupCodesPromptModal } from './components/BackupCodes/BackupCodesPromptModal'
import { PasskeyAddKeyNameModal } from './components/Passkey/AddKeyNameModal'
import { PasskeyCompletedModal } from './components/Passkey/CompletedModal'
import { WrappedPasskeyFailedModal } from './components/Passkey/FailedModal'
import { PasskeyLoadingModal } from './components/Passkey/LoadingModal'
import { PasskeyNoBrowserSupportModal } from './components/Passkey/NoBrowserSupportModal'
import { WrappedPasskeyRemoveModal } from './components/Passkey/RemoveModal'
import { SMSInputMobilePhoneNumberModal } from './components/SMS/InputMobilePhoneNumberModal'
import { SMSCompletedModal } from './components/SMS/CompletedModal'
import { WrappedSMSRemoveModal } from './components/SMS/RemoveModal'
import { SMSVerifyCodeModal } from './components/SMS/VerifyCodeModal'
import { TrustedDevices } from './components/MultiFactor/TrustedDevices'
import { selectHasTrustedDevices } from 'modules/trustedDevices'

const {
  featureFlags: { areTrustedDevicesEnabled },
} = __CONFIG__

export const MFASection: React.FC = () => {
  const isMFAEnabled = useSelector(selectIsTwoFactorAuthEnabled)
  const hasTrustedDevices = useSelector(selectHasTrustedDevices)

  return (
    <>
      <Section>
        <MultiFactorHeader />
        <MultiFactorContent />
        {isMFAEnabled ? <RecoveryCodes /> : null}
        {areTrustedDevicesEnabled && hasTrustedDevices ? (
          <TrustedDevices />
        ) : null}
      </Section>
      <SetupMFAModal />
      <PasskeyAddKeyNameModal />
      <PasskeyLoadingModal />
      <WrappedPasskeyFailedModal />
      <PasskeyCompletedModal />
      <PasskeyNoBrowserSupportModal />
      <WrappedPasskeyRemoveModal />
      <TOTPQRCodeModal />
      <TOTPSecretCodeModal />
      <TOTPVerifyCodeModal />
      <TOTPCompletedModal />
      <WrappedTOTPRemoveModal />
      <SMSInputMobilePhoneNumberModal />
      <SMSVerifyCodeModal />
      <SMSCompletedModal />
      <WrappedSMSRemoveModal />
      <BackupCodesModal />
      <BackupCodesPromptModal />
      <BackupCodesFailedModal />
      <EmailInputEmailModal />
      <EmailCompletedModal />
      <EmailVerifyCodeModal />
      <WrappedEmailRemoveModal />
      <PushQRCodeModal />
      <PushCompletedModal />
      <PushFailedModal />
      <WrappedPushRemoveModal />
      <SetupPrimaryMethodModal />
      <WrappedSubmitPasswordModal />
    </>
  )
}
