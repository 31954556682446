import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'
import createDataModule from 'utils/moduleCreator'
import { createSelector } from 'reselect'
import { closeVisibleModal } from './modals'
import { checkAuth } from 'features/auth/modules/auth'

const {
  api: { sso },
} = __CONFIG__

export const trustedDevicesDataKey = 'trustedDevices'

export interface TrustedDevice {
  device_id: string
  device_name: string
  device_model: string
  created_at: string
  simplified_agent: {
    agent: string | null
    is_ui_product: boolean
    os: string
  }
  user: string
  session: string
  is_current_device: boolean
}

const dataModule = createDataModule<TrustedDevice[]>(
  trustedDevicesDataKey,
  sso.paths.trustedDevices,
  api.ssoBase
)

export const {
  fetch: fetchTrustedDevices,
  selectData: selectTrustedDevices,
  selectIsRemoveLoading: selectIsTrustedDeviceRemoveLoading,
  REMOVE_DONE,
  reducer: trustedDevicesReducer,
} = dataModule

export const selectHasTrustedDevices = createSelector(
  selectTrustedDevices,
  (trustedDevices: TrustedDevice[]): boolean => !!trustedDevices?.length
)

export const selectCurrentTrustedDevice = createSelector(
  selectTrustedDevices,
  (trustedDevices: TrustedDevice[]) => {
    if (!trustedDevices?.length) return
    return trustedDevices.find(
      ({ is_current_device }: TrustedDevice) => is_current_device
    )
  }
)

export const removeMultipleTrustedDevices = (ids: string[]) => {
  const searchParams = new URLSearchParams()
  ids.forEach((id) => searchParams.append('id', id))
  return {
    type: `${trustedDevicesDataKey}/REMOVE`,
    urlPath: `?${searchParams}`,
  }
}

function* removeTrustedDeviceDoneSaga() {
  yield put(fetchTrustedDevices())
  yield put(checkAuth())
  yield put(closeVisibleModal())
}

function* subscribeToRemoveDoneSaga() {
  yield takeEvery(REMOVE_DONE, removeTrustedDeviceDoneSaga)
}

export function* trustedDevicesRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToRemoveDoneSaga)])
}
